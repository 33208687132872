import { Link } from "gatsby"
import React, { Component } from 'react'
import loadable from '@loadable/component'
import { Tabs, TabList, Tab, TabPanels, TabPanel } from "@reach/tabs";

import Loader from "../components/loader";

const Head = loadable(() => import("../components/head"));
const InnerLayout = loadable(() => import('../components/Innerlayout'));
const EngNFTMinting = loadable(() => import("../components/NFTMinting/english.js"));
const ArabicNFTMinting = loadable(() => import("../components/NFTMinting/arabic.js"));
const ChineseNFTMinting = loadable(() => import("../components/NFTMinting/chinese.js"));

const schema = {
  "@context": "http://schema.org/",
  "@graph": [
    {
      "@type": "product",
      "image": "https://softtik.com/images/nft-collection/invest-section-3.webp",
      "name": "Top NFT Minting Website Development Company",
      "description": "Venture with the top NFT minting website development company, Softtik, which provides feature-rich, flexible, and high-end solutions to its clients.",
      "brand": "Softtik Technologies",
      "aggregateRating": {
        "@type": "AggregateRating",
        "ratingValue": "4.7",
        "reviewCount": "119"
      }
    },
    {
      "@type": "FAQPage",
      "mainEntity": [{
        "@type": "Question",
        "name": "What is the NFT Minting Platform?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "<p><b>NFT Minting</b> platform is an interface that enables the users to mint/create their many digital assets like art, games, video, music and real estate to NFTs. Users have to pay a mint fee based on the underlying blockchain.</p>"
        }
      }, {
        "@type": "Question",
        "name": "How do I create a minting site for NFT?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "<p>You can develop a minting site for NFTs; you can utilize Whitelabel NFT minting software to create an <b>NFT minting</b> site, but it involves risks. The second method is to create a minting site from scratch, which can be costly but secure and efficient.</p>"
        }
      }, {
        "@type": "Question",
        "name": "Is Minting NFT profitable?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "<p>The phenomenal rise of the NFT space has increased the overall trading of digital assets over the last year. Due to this rise in popularity, NFT minting is in great demand and profitable.</p>"
        }
      }
      ]
    }
  ]
}

export class NFTMinting extends Component {

  constructor(props) {
    super(props);
    this.state = {
      lang: "English"
    }
  };


  async componentDidMount() {
    let region = localStorage.getItem('region');

    if (region == 'ae') this.setState({ lang: "Arabic" });
    else if (region == 'cn') this.setState({ lang: "China" });
    else this.setState({ lang: "English" });
  };

  render() {
    let { lang } = this.state;

    return (
      <>
        <InnerLayout header='Main' fallback={<Loader />}>
          <Head
            type="Website"
            fallback={<Loader />}
            schemaMarkup={schema}
            path="/nft-minting-website-development-services/"
            title="NFT Minting Website Development Company - Softtik"
            thumbnail="https://softtik.com/images/nft-collection/invest-section-3.webp"
            description="Venture with the top NFT minting website development company, Softtik, which provides feature-rich, flexible, and high-end solutions to its clients."
          />
          <main onWheel={this.onScroll}>
            <div id="wrapper">

              <div className={`collection-page game-development-page ${lang == "Arabic" && 'rtl'}`}>
                {lang == "Arabic"
                  ? <ArabicNFTMinting />
                  : lang == "China"
                    ? <ChineseNFTMinting />
                    : <EngNFTMinting />
                }
              </div>
            </div>
          </main>
        </InnerLayout >
      </>
    )
  };
};

export default NFTMinting;